import React, { Fragment, useEffect, useState } from "react"
import FilterProperties from "../components/properties/FilterProperties"
import FeaturedProperties from "../components/properties/FeaturedProperties"
import Layout from "../components/layout"
import SEO from "../components/seo"
import BannerTop from "../components/bannerTop"
import { Modal } from "react-responsive-modal"

const defaultFormContact = {
  nombre: "",
  correo: "",
  asunto: "Te contactaron de agenteinmobiliario.com",
  mensaje: "",
  celular: 0,
  telefono: 0,
  fecha: "",
  hora: "",
  rut: "",
  loading: false,
  request: false,
  requestMessage: "",
}

const OrdenVisita = props => {
  const [form, setForm] = useState(defaultFormContact)
  const [visibleModal, setVisibleModal] = useState(false)
  const [dataOrder, setDataOrder] = useState({
    code: null,
    nameAgent: "",
    emailAgent: "",
  })

  const onChangeInput = e => {
    setForm({ ...form, [e.target.name]: e.target.value })
    console.log(form)
  }

  const requestForm = async () => {
    setForm({ ...form, loading: true })
    var formdata = new FormData()
    formdata.append("name", form.nombre)
    formdata.append("email", form.correo)
    formdata.append("mobile", form.celular)
    formdata.append(
      "subject",
      "Orden de visita solicitada - agenteinmobiliario.cl"
    )
    formdata.append(
      "message",
      `LINK DE LA PROPIEDAD: <a href="https://www.agenteinmobiliario.cl/propiedades/?code=${dataOrder.code}">presione aquí</a><br/>PROPIEDAD CÓDIGO: ${dataOrder.code}<br/><br/>${form.mensaje}<br/>`
    )

    formdata.append("phone", form.telefono)
    formdata.append("date", form.fecha)
    formdata.append("hour", form.hora)
    formdata.append("rut", form.rut)
    formdata.append("nameAgent", dataOrder.nameAgent)
    formdata.append("emailAgent", dataOrder.emailAgent)

    setForm({ loading: true })
    var requestOptions = {
      method: "POST",
      body: formdata,
      redirect: "follow",
    }

    fetch(
      "https://www.agenteinmobiliario.cl/mailApi/sendmail.php",
      requestOptions
    )
      .then(response => response.text())
      .then(result => {
        handleFormReset()
        setForm({
          ...defaultFormContact,
          loading: false,
          requestMessage: (
            <p style={{ color: "green" }}>
              Muchas gracias, su orden de visita ha sido enviada con éxito y
              será respondida por uno de nuestros agentes en las siguientes 4
              horas hábiles.
            </p>
          ),
        })
      })
      .catch(error =>
        setForm({
          ...form,
          loading: false,
          requestMessage: (
            <p style={{ color: "red" }}>Error intenta nuevamente!</p>
          ),
        })
      )
  }

  const onSubmit = e => {
    e.preventDefault()
    requestForm()
  }

  const handleFormReset = () => {
    Array.from(document.querySelectorAll("input")).forEach(
      input => (input.value = "")
    )

    Array.from(document.querySelectorAll("textarea")).forEach(
      textarea => (textarea.value = "")
    )
  }

  useEffect(() => {
    let search = props.location.search
    let params = new URLSearchParams(search)
    console.log(params.get("nameAgent"))
    setDataOrder({
      code: params.get("id").replace("AGE", ""),
      nameAgent: params.get("nameAgent"),
      emailAgent: params.get("eAgent"),
    })
  }, [])

  return (
    <Layout>
      <SEO title="Nuestro equipo" />
      <BannerTop title="Agentes Inmobiliarios" />

      <div className="container contents lisitng-grid-layout">
        <div className="row">
          <div className="span9 main-wrap">
            <div className="main">
              <section className="listing-layout">
                <h3 className="title-heading">
                  Orden de visita para la propiedad Nª {dataOrder.code}
                </h3>
                <div className="inner-wrapper" style={{ borderBottom: "none" }}>
                  <form class="custom_form" onSubmit={event => onSubmit(event)}>
                  <label for="nombre">Nombre completo (*):</label>
                    <input
                      type="text"
                      name="nombre"
                      onChange={event => onChangeInput(event)}
                      required
                    />
                    <label for="email">E-mail (*):</label>
                    <input
                      type="email"
                      name="correo"
                      onChange={event => onChangeInput(event)}
                      required
                    />
                   
                    <label for="fecha">Fecha (*):</label>
                    <input
                      type="date"
                      name="fecha"
                      id="fecha"
                      required
                      onChange={event => onChangeInput(event)}
                    />
                    <label for="hora">Hora (entre las 9:00 y las 19:00):</label>
                    <input
                      type="time"
                      name="hora"
                      min="09:00"
                      max="19:00"
                      onChange={event => onChangeInput(event)}
                      placeholder="HH:MM"
                      required
                    />
                    <label for="celular">Celular (*):</label>
                    <input
                      type="text"
                      minLength="9"
                      name="celular"
                      onChange={event => onChangeInput(event)}
                      required
                    />
                    <label for="telefono">Telefono fijo:</label>
                    <input
                      type="text"
                      name="telefono"
                      minLength="9"
                      onChange={event => onChangeInput(event)}
                    />
                    <label for="rut">RUT (*):</label>
                    <input
                      type="text"
                      name="rut"
                      minLength="9"
                      onChange={event => onChangeInput(event)}
                      required
                    />
                    <label for="mensaje">Mensaje:</label>
                    <textarea
                      style={{ height: "100px" }}
                      name="mensaje"
                      cols="30"
                      rows="10"
                      onChange={event => onChangeInput(event)}
                    ></textarea>
                    <br /> <br />
                    <div style={{ display: "flex" }}>
                      {" "}
                      <input
                        type="checkbox"
                        required
                        style={{ maxWidth: "20px" }}
                      />
                      <label for="condiciones">
                        <a onClick={() => setVisibleModal(true)}>
                          Acepto las condiciones y términos del servicio
                        </a>
                      </label>
                    </div>
                    <br />
                    <br />
                    <input
                      type="hidden"
                      name="subject"
                      value="Orden de visita solicitada - agenteinmobiliario.com"
                    />
                    <div style={{ display: "flex" }}>
                      {" "}
                      <input
                        disabled={form.loading}
                        type="submit"
                        value={
                          form.loading ? "Enviando" : "Enviar orden de visita"
                        }
                        id="submit"
                        className="real-btn"
                        style={{ maxWidth: "300px" }}
                        name="submit"
                      />
                      <div
                        style={{
                          padding: "5px 20px",
                          fontSize: "15px",
                          fontWeight: "bold",
                        }}
                      >
                        {" "}
                        {form.requestMessage !== "" &&
                          !form.loading &&
                          form.requestMessage}
                      </div>
                    </div>
                  </form>
                </div>
              </section>
            </div>
          </div>
          <div className="span3 sidebar-wrap">
            <aside className="sidebar">
              <FeaturedProperties />
              <section className="widget advance-search">
                <h4 className="title search-heading">
                  Buscar Propiedad<i className="icon-search"></i>
                </h4>
                <div className="as-form-wrap">
                  <FilterProperties />
                </div>
              </section>
            </aside>
          </div>
        </div>
      </div>

      <Modal open={visibleModal} onClose={() => setVisibleModal(false)} center>
        <h1>Condiciones y Términos del Servicio</h1>

        <p>
          De interesarme por alguna de las propiedades ofrecidas por la empresa,
          “Desarrollo & Inversiones Inmobiliarias JC SpA.”, en adelante; “el
          Agente Inmobiliario”, me obligo a encargar para hacer los trámites
          ante el propietario para adquirirla o arrendarla, obligándome a pagar
          la comisión que corresponda según lo indicado más abajo, con los
          impuestos correspondientes.
        </p>
        <p>
          Declaro bajo juramento, contar con las facultades para solicitar esta
          orden y para comprometerme o comprometer a mi mandante, si lo hubiere
          a cumplir todas y cada una de las obligaciones que contraigo al
          subscribirlas. Para proceso de venta, el dos por ciento más impuesto
          por la factura emitida sobre el valor de venta, con un mínimo de 40
          UF.
        </p>
        <p>
          Para arriendo (menor a dos años), un mes, más impuesto por la factura
          emitida, sobre el valor del arriendo. En caso de arriendo con
          administración, quince días de arriendo más impuesto y comisión. En
          caso que el contrato de arriendo sea superior a dos años, el
          arrendador y el arrendatario deberán pagar una comisión equivalente al
          dos por ciento más impuesto por la factura emitida, cada uno, del
          valor que resulte de la sumatoria de todos los meses del contrato de
          arrendamiento.
        </p>
        <p>
          Me comprometo a pagar a la empresa, “Desarrollo & Inversiones
          Inmobiliarias JC SpA.”, el doble a la comisión correspondiente, como
          pena, si directa o indirectamente procediere a la adquisición o
          arriendo prescindiendo en cualquier forma de la mediación de nuestra
          empresa y de sus agentes inmobiliarios; y/o si se compra o arrienda
          alguna de las propiedades aquí ofrecidas, por un tercero que haga uso
          de la información contenida en esta orden de visita. Para todos los
          efectos, las partes fijan como domicilio la ciudad de Santiago,
          prorrogando la competencia a sus tribunales ordinarios de justicia.
        </p>
        <br />
        <p>
          {" "}
          Los nombres de dominio: www.agenteinmobiliario.cl,
          www.arriendoinmobiliario.cl, www.circuloinversionista.cl y
          www.trustrent.cl , se encuentran debidamente registradas en NIC Chile.
        </p>
      </Modal>
    </Layout>
  )
}

export default OrdenVisita
